import React from 'react';
import Layout from '../layouts/index';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <h1 style={{ textAlign: 'center', marginTop: 100 }}>
          <b>404</b>
        </h1>
        <h3 style={{ textAlign: 'center', marginTop: 12 }}>
          <b>This page does not exist.</b>
        </h3>
        <p style={{ textAlign: 'center', marginTop: 18 }}>
          Please click a link in the navigation bar to visit one that actually
          does.
        </p>
      </Layout>
    );
  }
}

export default NotFoundPage;
